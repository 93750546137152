<style lang="scss">
@use "assets/scss/components/navbar.scss";
</style>

<template>
  <div class="w-16" @mouseover="mouseOver" @mouseleave="mouseLeave">
    <div class="cnavbar white-section w-16 h-full relative !p-0" :disablePadding="true" :expanded="expend">
      <nav>
        <div class="top cnav_list">
          <NuxtLink v-for="link in navLinks" :key="link.url" :to="link.url" class="cnav_link relative"
                    activeClass="active">
            <p class="decorator" />
            <div class="flex gap-2 items-center">
              <i :class="link.icon" class="aspect-square"></i>
              <span class="cnav_name">{{ link.name }}</span>
            </div>
          </NuxtLink>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import { VsAvatar } from "vuesax-ts";
import BentoBlock from "~/components/orders/BentoBlock.vue";


export default {
  name: "Navbar",
  components: {
    BentoBlock,
    VsAvatar
  },
  props: {
    navLinks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      expend: false,
      timeouts: {},
    };
  },
  methods: {
    mouseOver() {
      this.timeouts.expend = setTimeout(() => {
        this.expend = true;
      }, 300);
    },

    mouseLeave() {
      if (this.dropdownIsOpen) return clearTimeout(this.timeouts.expend);
      clearTimeout(this.timeouts.expend);
      setTimeout(() => {
        this.expend = false;
        this.dropdownIsOpen = false;
      }, 300);
    }
  }
};
</script>
