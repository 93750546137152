<style lang="scss">
@use "assets/scss/components/header.scss";
</style>

<template>
  <header class="white-section !px-5">
    <div class="flex flex-1 justify-between">
      <div class="left">
        <img alt="Logo de la marque" class="h-12" src="/images/logo.png">
      </div>
      <div class="right flex gap-6">
        <UPopover v-if="!isPrinter && $account && $account.subscription_plan" v-model:open="PopoverOpen" class="popup">
          <div>
            <Button className="z-10 px-4 h-10" type="default" text="Solde et forfait" icon="i-heroicons-bolt" />
          </div>

          <template #content>
            <div class="py-4 pl-4 pr-16 white-section z-100">
              <h3 class="font-bold">Solde & forfait :</h3>
              <div class="pt-4 grid gap-4">
                <div>
                  <p class="font-medium">{{
                      $account.subscription_plan?.displayname ? t("subscriptions." + $account.subscription_plan?.displayname) : "Aucun"
                    }}</p>
                  <NuxtLink class="little-text underline" to="/subscriptions">Gérer mon abonnement</NuxtLink>
                </div>
                <div>
                  <p class="font-medium">Balance: {{ text2Price($account.balance) }}</p>
                  <NuxtLink class="little-text underline" to="/settings/billing#credit">Créditer mon compte</NuxtLink>
                </div>
                <div>
                  <p class="font-medium">Solde minimal: {{ text2Price($account.minimal_balance) }}</p>
                  <NuxtLink class="little-text underline" to="/settings/billing#credit">Gérer mon solde minimal
                  </NuxtLink>
                </div>
              </div>
            </div>
          </template>
        </UPopover>
        <div v-if="!isPrinter && $account" class="dropdown">
          <UDropdownMenu :items="items" :content="{ side: 'bottom-start' }" color="neutral" variant="outline">
            <div class="flex gap-4 items-center cursor-pointer">
              <VsAvatar badge badgeColor="success"
                        class="cursor-pointer !rounded-full" size="45">
                <NuxtImg :src="gravatarUrl"></NuxtImg>
              </VsAvatar>
              <div class="text-base/6">
                <h6 class="text-h6 d-flex align-center text-black font-weight-semibold">{{ $user.firstname }}
                  {{ truncate($user.lastname, 1, ".") }}</h6>
                <span class="text-subtitle-2 font-weight-medium text-grey-100 max-w-12 text-ellipsis">{{
                    $account?.displayname || "Non défini"
                  }}</span>
              </div>
            </div>
          </UDropdownMenu>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { text2Price, textToSha256, truncate } from "../utils/global.mjs";
import { fetch } from "~/utils/helper";
import { VsAvatar } from "vuesax-ts";
import { feedbackIntegration } from "@sentry/vue";

const { t } = useI18n();
const PopoverOpen = ref(false);

const { $account, $user } = useNuxtApp();

const gravatarUrl = ref("https://gravatar.com/avatar/000000000000000000000000000000000000000000000000000000?d=mp");


watch(() => PopoverOpen.value, (value) => {
  if (value) {
    const auth = useAuth();
    auth.refreshAuthStatus();
  }
});

const items = [[{
  label: "Mon compte",
  icon: "i-heroicons-cog-8-tooth",
  onSelect: () => {
    navigateTo("/settings/user");
  }
}], [{
  label: "Mon entreprise",
  icon: "i-heroicons-building-office-2-20-solid",
  onSelect: () => {
    navigateTo("/settings/account");
  }
}, {
  label: "Mon équipe",
  icon: "i-heroicons-users-20-solid",
  onSelect: () => {
    navigateTo("/team");
  }
}], [{
  label: "Développeurs",
  icon: "i-heroicons-wrench-20-solid",
  onSelect: () => {
    navigateTo("/developers");
  }
}], [{
  label: "Déclarer un bug",
  icon: "i-heroicons-information-circle",
  onSelect: async () => {
    const { $user } = useNuxtApp();
    const feedback = feedbackIntegration({
      autoInject: false,
      useSentryUser: {
        email: $user.email,
        username: $user.firstname + " " + $user.lastname
      },
      showName: false,
      showEmail: false
    });
    const form = await feedback.createForm();
    form.appendToDom();
    form.open();
  }
}], [{
  label: "Déconnexion",
  icon: "i-heroicons-arrow-left-on-rectangle",
  onSelect: () => {
    logout();
  }
}]];
let $swal = null;

onBeforeMount(async () => {
  if ($user?.email) {
    gravatarUrl.value = `https://www.gravatar.com/avatar/${ await textToSha256($user?.email) }?d=initials&initials=${ $user.firstname[0] }${ $user.lastname[0] }`;
  } else if ($user?.firstname && $user?.lastname) {
    gravatarUrl.value = `https://www.gravatar.com/avatar/000000000000000000000000000000000000000000000000000000?d=initials&initials=${ $user.firstname[0] }${ $user.lastname[0] }`;
  }
  if ($account && $account?.subscription_plan) {
    items[1].push({
      label: "Mon offre",
      icon: "i-heroicons-credit-card-20-solid",
      onSelect: () => {
        navigateTo("/settings/billing");
      }
    });
  }
  let userAccounts = await accounts.value;
  if (userAccounts.length > 1) {
    items.unshift([{
      label: "Changer d'entreprise",
      icon: "i-heroicons-arrows-right-left-20-solid",
      onSelect: async () => {
        await switchAccount();
      }
    }])
  }
  if ($user?.isAdmin) {
    const route = useRoute();
    if (route.path.startsWith("/management")) {
      items.unshift([{
        label: "Interface utilisateur",
        icon: "i-heroicons-arrow-left-on-rectangle",
        onSelect: () => {
          window.location.href = "/";
        }
      }]);
    } else {
      items.unshift([{
        label: "Management",
        icon: "i-heroicons-arrow-left-on-rectangle",
        onSelect: () => {
          window.location.href = "/management";
        }
      }]);
    }
  }
});

const switchAccount = async () => {
  if (!$swal) $swal = useNuxtApp().$swal;
  $swal.fire({
    title: "Chargement...",
    text: "Chargement des comptes...",
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    async didOpen(_) {
      const accounts = await fetchAccounts();
      // transform accounts to { id: displayname }
      $swal.fire({
        title: "Changer de compte",
        text: "Choisissez un compte pour continuer",
        input: {
          label: "Vos comptes",
          type: "select",
          options: accounts.map(account => {
            return {
              value: account.id,
              label: account.displayname
            };
          }),
          value: $account.id,
          validator: (value) => {
            if (!value) {
              return "Vous devez choisir un compte pour continuer";
            }
          }
        },
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Changer",
        cancelButtonText: "Annuler",
        showLoaderOnConfirm: true,
        async preConfirm(value) {
          const data = await fetch(`/accounts/@me`, {
            method: "POST",
            body: {
              accountId: value
            }
          });
          if (data.success) {
            window.location.reload();
          } else {
            $swal.fire({
              title: "Erreur",
              text: data.message,
              icon: "error"
            });
          }
        }
      });
    }
  });
};

const fetchAccounts = async () => {
  const data = await fetch("/accounts");
  return data.accounts;
};

const accounts = computed(() => {
  return fetchAccounts();
});

</script>

<script>
export default {
  name: "Header",
  props: {
    isPrinter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fetchingAccounts: false
    };
  }
};
</script>
